<!-- 
// +----------------------------------------------------------------------
// | LightPicture [ 图床 ]
// +----------------------------------------------------------------------
// | 企业团队图片资源管理系统
// +----------------------------------------------------------------------
// | Github: https://github.com/osuuu/LightPicture
// +----------------------------------------------------------------------
// | Copyright © http://picture.h234.cn All rights reserved.
// +----------------------------------------------------------------------
// | Author: Team <admin@osuu.net>
// +---------------------------------------------------------------------- 
-->
<template>
  <div>
    <div class="os-url-main">
      <Divider>LightPicture</Divider>
      <div class="os-main">
        <div class="os-item">
          <span class="osuu-net geek-github"></span>
          <p>github</p>
        </div>
        <div class="os-item">
          <span class="osuu-net geek-gitee2"></span>
          <p>gitee</p>
        </div>
        
      </div>
      <!-- <p>作者：@王多余</p> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {
   
  },
};
</script>

<style lang="less" scoped>
@import url("../assets/css/theme.min.css");
p{
  text-align: center;
}
</style>